
.featured_item {
    position: relative;
}

.featured_item a {
    position: absolute;
    width: 100%;
    text-decoration: none;
    bottom: 0px;
    right: 0px;
}

.featured_image {
    height: 330px;
    background-size: cover !important;
    background-repeat: no-repeat !important;  
}

.featured_caption {
    color: #ffffff;
    top: 0px;
    width: 100%;
    font-weight: 300;
    padding: 10px;
    background: rgba(0, 0, 0, .4);
    font-size: 20px;
    box-sizing: border-box;
}


@media (max-width:480px) {

    .featured_image { 
        height: 80px;
    }
    
 }


 @media (min-width:481px) and (max-width:1024px) {

    .featured_image { 
        height: 150px;
    }
 }


